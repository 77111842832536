import React from 'react';
import Config from '../../../data/siteConf';

const FindUs = () => (
  <div className="address-container">
    <h5 className="title footer-title is-size-5 is-uppercase">Find Us</h5>
    <span className="address-company has-text-weight-semibold is-size-5">{Config.contact.company}</span>
    <span className="address">{Config.contact.address1}</span>
    <span className="address">
      {Config.contact.city}, {Config.contact.state} {Config.contact.zipcode}
    </span>
    <span className="address-phone">{Config.contact.phone}</span>
    <br />
    <span className="address-email">
      <a href={`mailto:${Config.contact.email}`}>{Config.contact.email}</a>
    </span>
  </div>
);

export default FindUs;
