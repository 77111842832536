import React from 'react';
import Config from '../../../data/siteConf';
import Callout from '../Callout';

const AffiliateLink = (props) => (
  <a className="affiliation-link" href={props.url} alt={props.label}>
    {props.label}
  </a>
);

const Affiliations = () => (
  <div className="affiliations-container">
    <h5 className="title footer-title is-size-5 is-uppercase">Affiliations</h5>
    <p>
      The <Callout text="Frying Pan River Lodge" /> is a division of Frying Pan Anglers. Click the links below to visit
      our other properties.
    </p>
    <div className="affiliation-links">
      {Config.affiliateLinks.map((aff) => {
        return <AffiliateLink url={aff.url} label={aff.label} key={aff.url} />;
      })}
    </div>
  </div>
);

export default Affiliations;
