import React from 'react';
import { Link } from 'gatsby';
import logo from '../../images/fprl-logo-v3.png';

const NavLink = (props) => (
  <Link to={props.to} className="navbar-item" activeClassName="is-active">
    {props.label}
  </Link>
);

const TopNav = class extends React.Component {
  componentDidMount() {
    // Get all "navbar-burger" elements
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    // Check if there are any navbar burgers
    if ($navbarBurgers.length > 0) {
      // Add a click event on each of them
      $navbarBurgers.forEach((el) => {
        el.addEventListener('click', () => {
          // Get the target from the "data-target" attribute
          const target = el.dataset.target;
          const $target = document.getElementById(target);

          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
        });
      });
    }
  }

  render() {
    return (
      <nav className="navbar top-navbar is-fixed-top" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Frying Pan River Lodge" style={{ width: '175px' }}>
            <img src={logo} alt="Frying Pan River Lodge" />
          </Link>
          <div className="navbar-burger burger" data-target="navMenu">
            <span />
            <span />
            <span />
          </div>
        </div>
        <div id="navMenu" className="navbar-menu">
          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">Lodging</a>
              <div className="navbar-dropdown">
                <NavLink to="/lodging/riverside-units/" label="Riverside Units" />
                <NavLink to="/lodging/river-cabin/" label="River Cabin" />
                <NavLink to="/lodging/house/" label="House" />
              </div>
            </div>
            <NavLink to="/basalt-fly-fishing/" label="Fly Fishing" />
            <NavLink to="/basalt-activities/" label="About Basalt" />
            <NavLink to="/contact/" label="Contact" />
          </div>
        </div>
      </nav>
    );
  }
};

export default TopNav;
