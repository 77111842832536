import React from 'react';
import Helmet from 'react-helmet';
import Footer from '../footer/Footer';
import TopNav from '../header/TopNavigation';
import Config from '../../../data/siteConf';
import '../../scss/app.scss';
import 'react-image-lightbox/style.css';

const Layout = ({ children }) => (
  <div>
    <Helmet
      title={Config.siteTitleAlt}
      meta={[
        { name: 'description', content: Config.siteDescription },
        { name: 'msapplication-TileColor', content: '#da532c' },
        { name: 'theme-color', content: '#ffffff' }
      ]}
      link={[
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: '/favicon-32x32.png' },
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: '/favicon-16x16.png' },
        { rel: 'mask-icon', href: '/safari-pinned-tab.svg', color: '#5bbad5' },
        { rel: 'manifest', href: '/site.webmanifest' }
      ]}
      htmlAttributes={{
        class: 'has-navbar-fixed-top',
        lang: 'en'
      }}
    />
    <TopNav />
    {children}
    <Footer />
  </div>
);

export default Layout;
