import React from 'react';
import FindUs from './FindUs';
import Affiliations from './Affiliations';

const Footer = () => (
  <footer className="footer">
    <div className="columns">
      <div className="column">
        <Affiliations />
      </div>
      <div className="column" />
      <div className="column">
        <FindUs />
      </div>
    </div>
  </footer>
);

export default Footer;
