module.exports = {
  siteTitle: 'Frying Pan River Lodge', // Site title.
  siteTitleShort: 'FPR Lodge', // Short site title for homescreen (PWA). Preferably should be under 12 characters to prevent truncation.
  siteTitleAlt: 'Frying Pan River Lodge - Lodging on the Frying Pan River in Basalt Colorado', // Alternative site title for SEO.
  siteDescription:
    'Frying Pan River Lodge, Located in downtown Basalt Colorado, provides lodging on the Frying Pan River with private water and great fly fishing for trout.', // Website description used for RSS feeds/meta description tag.
  siteGATrackingID: '', // Tracking code ID for google analytics.
  siteLogo: '/logos/logo-1024.png', // Logo used for SEO and manifest.
  siteUrl: 'fryingpanriverlodge.com', // Domain of your website without pathPrefix.
  pathPrefix: '', // Prefixes all links. For cases when deployed ie: example.github.io/gatsby-material-starter/.
  dateFromFormat: 'YYYY-MM-DD', // Date format used in the frontmatter.
  dateFormat: 'MM/DD/YYYY', // Date format for display.
  copyright: 'Copyright © 2018. Frying Pan River Lodge',
  contact: {
    company: 'Frying Pan River Lodge',
    address1: '100 Frying Pan Road',
    city: 'Basalt',
    state: 'CO',
    zipcode: '81621',
    phone: '970 927-9927',
    email: 'robyn@fryingpananglers.com'
  },
  contactCalloutText: 'Check Availability',
  affiliateLinks: [
    {
      label: 'Taylor Creek Cabins',
      url: 'http://taylorcreekcabins.com'
    },
    {
      label: 'The Aspenalt',
      url: 'http://aspenalt.com'
    },
    {
      label: 'Frying Pan Anglers',
      url: 'http://www.fryingpananglers.com'
    },
    {
      label: 'South Platte Fly Shop',
      url: 'https://southplatteflyshop.com'
    },
    {
      label: 'The San Juan Angler',
      url: 'http://www.thesanjuanangler.com/'
    }
  ],
  guideTrips: [
    { desc: 'Full Day Wade, 1 Angler', rate: 435 },
    { desc: 'Full Day Wade, 2 Anglers', rate: 530 },
    { desc: 'Full Day Wade, 3 Anglers', rate: 695 },
    { desc: 'Full Day Float, 1 or 2 Anglers', rate: 615 }
  ],
  basaltActivitiesPage: {
    path: '/basalt-activities',
    title: 'Activities in Basalt Colorado',
    metaDescription: 'Thing to do in Basalt Colorado',
    imageBasalt: {
      src:
        'https://res.cloudinary.com/mendtechnologies/image/upload/c_scale,dpr_auto,f_auto,fl_progressive,q_auto,w_840/v1543950777/fprl/basalt/downtown-basalt-midland-avenue.gif',
      caption: 'Midland Ave in downtown Basalt, CO'
    },
    imageDining: {
      src:
        'https://res.cloudinary.com/mendtechnologies/image/upload/c_scale,dpr_auto,f_auto,fl_progressive,q_auto,w_450/fprl/basalt/downtown-basalt-outdoor-patio.jpg',
      caption: 'Midland Ave in downtown Basalt, CO'
    },
    imageShopping: {
      src:
        'https://res.cloudinary.com/mendtechnologies/image/upload/c_scale,dpr_auto,f_auto,fl_progressive,q_auto,w_450/fprl/basalt/downtown-basalt-old-buildings.gif',
      caption: 'Buildings in downtown Basalt, CO'
    },
    imageOutdoorRec: {
      src:
        'https://res.cloudinary.com/mendtechnologies/image/upload/c_scale,dpr_auto,f_auto,fl_progressive,q_auto,w_450/fprl/basalt/ruedi-vista.gif',
      caption: 'Ruedi Reservoir near Basalt, CO'
    }
  },
  basaltFlyFishingPage: {
    path: '/basalt-fly-fishing',
    title: 'Fly Fishing near Basalt Colorado',
    metaDescription: 'World Class Fly Fishing near Basalt Colorado on the Roaring Fork, Frying Pan and Colorado River.',
    imageRfrIsland: {
      src:
        'https://res.cloudinary.com/mendtechnologies/image/upload/c_scale,dpr_auto,f_auto,fl_progressive,q_auto,w_450/fprl/fly-fishing/fly-fishing-roaring-fork-river-island.jpg',
      caption: 'Fly fishing on the Roaring Fork River near Basalt Colorado in the fall'
    },
    imageFprPool: {
      src:
        'https://res.cloudinary.com/mendtechnologies/image/upload/c_scale,dpr_auto,f_auto,fl_progressive,q_auto,w_450/fprl/fly-fishing/fly-fishing-frying-pan-river-pool.jpg',
      caption: 'Fly Fishing the edge of a pool on Frying Pan River near Basalt Colorado'
    },
    imageFprRun: {
      src:
        'https://res.cloudinary.com/mendtechnologies/image/upload/f_auto,q_auto,w_450/a_270/v1543978591/fprl/fly-fishing/fly-fishing-frying-pan-river-run.jpg',
      caption: 'Fly Fishing a run on Frying Pan River near Basalt Colorado'
    }
  },
  contactPage: {
    path: '/contact',
    title: 'Contact Frying Pan River Lodge',
    metaDescription: ''
  },
  lodging: {
    specialText:
      'Book a single full day wading or floating guide trip with Frying Pan Anglers and receive a 20% discount off your nightly rate.',
    specialTitle: 'Fish and Save Rate',
    specialDiscountPercent: 0.2,
    specialDescription:
      'In order to qualify for the fish and save rate you must take either a full day wade trip or a full day float trip.',
    privateWater: 'Stay at the lodge and get access to over 150 yards of private water on the Frying Pan River.',
    policies: {
      checkout: 'Check out 11am',
      checkin: 'Check in 3pm',
      pets: 'No Pets Allowed',
      smoking: 'No Smoking',
      minstay: 'Min Stay 1 Night',
      maxstay: 'Max Stay 7 Night'
    },
    sharedImages: [
      {
        src:
          'https://res.cloudinary.com/mendtechnologies/image/upload/c_scale,q_auto,w_800/fprl/private-water-lodge.jpg',
        caption: 'Frying Pan River Lodge private water access for guests'
      },
      {
        src:
          'https://res.cloudinary.com/mendtechnologies/image/upload/c_scale,q_auto,w_800/fprl/private-water-lodge-2.jpg',
        caption: 'Frying Pan River Lodge private water access for guests'
      }
    ],
    riversideUnits: {
      path: '/lodging/riverside-units',
      title: 'Riverside Units',
      metaDescription: 'Riverside Units offer a great value with beautiful riverside views in downtown Basalt, CO',
      images: [
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/image/upload/c_scale,q_auto,w_800/fprl/riverside-unit/riverside-units-exterior.jpg',
          caption: 'Riverside unit front view from outside'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/riverside-unit/fprl-riverside-unit-bedroom.jpg',
          caption: 'Riverside unit full bedroom'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/riverside-unit/fprl-riverside-unit-entrance.jpg',
          caption: 'Riverside unit entrance'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/riverside-unit/fprl-riverside-unit-kitchen.jpg',
          caption: 'Riverside unit kitchenette'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/riverside-unit/fprl-riverside-unit-bathroom.jpg',
          caption: 'Riverside unit bathroom'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/riverside-unit/fprl-riverside-unit-shower.jpg',
          caption: 'Riverside unit shower'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/riverside-unit/fprl-riverside-unit-river-view.jpg',
          caption: 'Riverside unit river view'
        },
        {
          src: 'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/private-water-lodge-2.jpg',
          caption: 'Private water access for lodge guests'
        }
      ],
      guests: 4,
      bedrooms: 1,
      bath: 1,
      beds: 2,
      bedsText: 'Studio, 2 Queen Beds',
      amenities:
        'Mini Refrigerator, Coffee Maker, Microwave, High Speed Wifi, Dish TV, Radiant Heat, Private Water Access',
      rate: 130,
      deposit: 100,
      cancelation: 'Cancel up to 7 days to receive a full refund.',
      minstay: 'Min Stay 1 Night',
      maxstay: 'Max Stay 7 Night'
    },
    cabin: {
      path: '/lodging/riverside-cabin',
      title: 'Riverside Cabin',
      metaDescription: 'The river cabin offers a great space to...',
      images: [
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/river-cabin/river-cabin-front-entrance.jpg',
          caption: 'Cabin front entrance view'
        },
        {
          src: 'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/cabin-back.jpg',
          caption: 'Cabin outside river side view.'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/river-cabin/fprl-cabin-dining-living-room.jpg',
          caption: 'Cabin dining room and living room'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/river-cabin/fprl-cabin-kitchen.jpg',
          caption: 'Cabin kitchen'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/river-cabin/fprl-cabin-bedroom.jpg',
          caption: 'Cabin bedroom'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/river-cabin/fprl-cabin-bedroom-2.jpg',
          caption: 'Cabin bedroom sitting area'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/river-cabin/fprl-cabin-bathroom-vanity.jpg',
          caption: 'Cabin bathroom vanity'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/river-cabin/fprl-cabin-bathroom-toliet.jpg',
          caption: 'Cabin bathroom toliet'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/river-cabin/fprl-cabin-bathroom-shower.jpg',
          caption: 'Cabin bathroom shower'
        },
        {
          src: 'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/cabin-deck-view.jpg',
          caption: 'Cabin river side deck'
        },
        {
          src: 'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/cabin-deck-view-2.jpg',
          caption: 'Cabin river side deck'
        },
        {
          src: 'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/private-water-lodge.jpg',
          caption: 'Private water access for lodge guests'
        },
        {
          src: 'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/private-water-lodge-2.jpg',
          caption: 'Private water access for lodge guests'
        }
      ],
      guests: 4,
      bedrooms: 1,
      bath: 1,
      beds: 2,
      bedsText: '1 Bedroom, 2 Queen Beds',
      amenities:
        'Full Kitchen, Living/Dining Room, Riverside Wrap Around Deck, Outdoor Gas Grill, Deck Furniture, High Speed Wifi, Dish TV, Radiant Heat, Private Water Access',
      rate: 250,
      special: '',
      specialRate: '',
      deposit: 200,
      cancelation: 'Cancel up to 30 days to receive a full refund.',
      minstay: 'Min Stay 1 Night',
      maxstay: 'Max Stay 7 Night'
    },
    house: {
      path: '/lodging/house',
      title: 'House',
      metaTitle: 'The House at Frying Pan River Lodge offers',
      metaDescription: 'The House at Frying Pan River Lodge offers',
      images: [
        {
          src: 'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-front.jpg',
          caption: 'House from front entrance'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-view-from-entrance.jpg',
          caption: 'House inside from front entrance'
        },
        {
          src: 'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-kitchen.jpg',
          caption: 'Frying Pan River Lodge House full kitchen'
        },
        {
          src: 'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-dining-room.jpg',
          caption: 'Frying Pan River Lodge House dining room'
        },
        {
          src: 'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-living-room.jpg',
          caption: 'Frying Pan River Lodge House living room'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-half-bathroom.jpg',
          caption: 'Frying Pan River Lodge House half bathroom'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-full-bathroom.jpg',
          caption: 'Frying Pan River Lodge House full bathroom'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-full-bathroom-2.jpg',
          caption: 'Frying Pan River Lodge House full bathroom'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-full-bathroom-3.jpg',
          caption: 'Frying Pan River Lodge House full bathroom'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-bedroom-double-queen-facing.jpg',
          caption: 'Frying Pan River Lodge House double queen bedroom facing queen beds'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-bedroom-double-queen-facing-2.jpg',
          caption: 'Frying Pan River Lodge House double queen bedroom facing queen beds'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-bedroom-double-queen-facing-3.jpg',
          caption: 'Frying Pan River Lodge House double queen bedroom facing queen beds'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-bedroom-double-queen-sideby.jpg',
          caption: 'Frying Pan River Lodge House double queen bedroom'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-bedroom-double-queen-sideby-2.jpg',
          caption: 'Frying Pan River Lodge House double queen bedroom'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-bedroom-single-queen-r1.jpg',
          caption: 'Frying Pan River Lodge House single queen bedroom'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-bedroom-queen-single-r1-2.jpg',
          caption: 'Frying Pan River Lodge House single queen bedroom'
        },
        {
          src:
            'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/house/fprl-house-bedroom-single-queen-r2.jpg',
          caption: 'Frying Pan River Lodge House single double bedroom'
        },
        {
          src: 'https://res.cloudinary.com/mendtechnologies/f_auto,q_auto,w_800/fprl/private-water-lodge-2.jpg',
          caption: 'Private water access for lodge guests'
        }
      ],
      guests: 12,
      bedrooms: 4,
      bath: 1.5,
      beds: 6,
      bedsText: '4 Bedrooms, 2 Queen Beds, 4 Double Beds',
      amenities:
        'Full Kitchen with Island, Dining Room, Living Room, Covered Front Deck with River Views, Rear Patio, Deck Furniture, Outdoor Gas Grill, High Speed Wifi, Dish TV, Radiant Heat, Private Water Access',
      rate: 325,
      special: '',
      specialRate: '',
      deposit: 200,
      cancelation: 'Cancel up to 30 days to receive a refund.',
      minstay: 'Min Stay 2 Night',
      maxstay: 'Max Stay 7 Night'
    }
  }
};
